import React from "react";
import GoogleMapReact from "google-map-react";
import pin from "./pin.png";

const markerStyle = {
  position: "absolute",
  top: "100%",
  left: "50%",
  transform: "translate(-50%, -100%)"
};


/*
  I found this class on the internet as an example and only modified it a bit.
  There are some problems to be solved, like if the tracker is outside of the 
  current map, try what happenes :). 
*/
class SimpleMap extends React.Component {
  static defaultProps = {
    zoom: 8,
    centerLati: 46.084671,
    centerLong: 14.746973
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: "100vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyA16d9FJFh__vK04jU1P64vnEpPc3jenec"
          }}
          center={{
            lat: this.props.centerLati,
            lng: this.props.centerLong
          }}
          defaultZoom={this.props.zoom}
        >
          { this.props.latitude && this.props.longitude &&
            <div lat={this.props.latitude} lng={this.props.longitude}>
              <img style={markerStyle} src={pin} alt="pin" />
            </div>
          }
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap
