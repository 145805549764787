import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import SimpleMap from "./Map"
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider, TextField, Button } from '@mui/material'
import "./styles.css"

const App = () => {
  useEffect(() => {
    document.title = 'Location Tracker'
  }, [])

  ///////////
  // STATE //
  ///////////
  const [deviceName, setDeviceName] = useState()
  const [batteryVoltage, setBatteryVoltage] = useState()
  const [latitudeDegree, setLatitudeDegree] = useState()
  const [longitudeDegree, setLongitudeDegree] = useState()
  const [locationAge, setLocationAge] = useState()
  const [pingAge, setPingAge] = useState()
  const [trackingRequested, setTrackingRequested] = useState()
  const [status, setStatus] = useState()
  const [deviceData, setDeviceData] = useState("")
  const [showDataOnMap, setShowDataOnMap] = useState(false)

  ///////////////
  // FUNCTIONS //
  ///////////////
  const SECONDS_IN_MINUTE = 60
  const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * 60
  const SECONDS_IN_DAY = SECONDS_IN_HOUR * 24
  const timeFromSeconds = (seconds) => {
    if (seconds) {
      const days = Math.floor(seconds / SECONDS_IN_DAY)
      seconds -= days * SECONDS_IN_DAY
      const hours = Math.floor(seconds / SECONDS_IN_HOUR)
      seconds -= hours * SECONDS_IN_HOUR
      const min = Math.floor(seconds / SECONDS_IN_MINUTE)
      var dayHourMin_string = ""
      if (days === 1) dayHourMin_string += days + " day "
      else if (days >= 2) dayHourMin_string += days + " days "
      if (hours > 0) dayHourMin_string += hours + " h "
      dayHourMin_string += min + " min"
      return dayHourMin_string
    }
  }

  const trackingStatus = (status) => {
    if (status === "1") return "active "
    else if (status === "0") return "disabled "
  }

  const negateTracking = (status) => {
    if (status === "1") return 0
    else if (status === "0") return 1
  }

  const voltageToPercentage = (voltage) => {
    if (voltage) {
      var percent = (voltage - 3.2) / 0.9 * 100
      if (percent > 100) percent = 100
      if (percent < 0) percent = 0
      return percent.toFixed(2) + "%"
    }
  }

  const isStatusErrorText = (status) => {
    if (status === "failure") return "Unknown device!"
  }

  const isStatusError = (status) => {
    if (status === "failure") return true
  }

  const searchButtonClicked = async () => {
    let user = {
      "name": deviceName
    }

    // Must be POST since we supply data in the body. 
    // Usually you would use a GET request and supply data as a query parameter, but the endpoint isn't implemented this way.
    // The problem is that fetch() wont allow you send a GET request that has a body, so we use POST as a workaround.
    // Blame Darjo for this :)
    const res = await fetch("https://frombora.si/track/api_get_general_data.php", {
      method: 'POST',
      body: JSON.stringify(user)
    })

    const data = await res.json()
    setDeviceData(data);
    setShowDataOnMap(true);

    setBatteryVoltage(data.battery)
    setLatitudeDegree(data.latitude)
    setLongitudeDegree(data.longitude)
    setLocationAge(data.location_age_s)
    setPingAge(data.ping_age_s)
    setTrackingRequested(data.tracking_requested)
    setStatus(data.status)
  }



  const switchTracking = async () => {
    let data = {
      "name": deviceName,
      "tracking_request": negateTracking(trackingRequested)
    }
    const res = await fetch("https://frombora.si/track/api_set_tracking.php", {
      method: 'POST',
      body: JSON.stringify(data)
    })

    await res.json()
    searchButtonClicked()
  }


  const Dashboard = () => {

    if (deviceData === "" || showDataOnMap === false) {
      return null;
    }

    if (deviceData === undefined) {
      return null;
    }
    /**
     * 
    const { status, iddevices, mac, tracking_requested, battery,
      charging, last_active, added, code_version,
      latitude, longitude, location_time, location_age_s, ping_age_s } = deviceData;
     */

    const { tracking_requested, latitude, longitude } = deviceData;
    let statusText = "as";

    if (tracking_requested === "1") {
      statusText = "turn off tracking";
    }
    if (tracking_requested === "0") {
      statusText = "turn on tracking";
    }

    return (
      <div className="dashboard">

        <div className="card">
          {"Battery: " + voltageToPercentage(batteryVoltage)}
        </div>

        <div className="card">
          {"Latitude: " + latitude}
        </div>

        <div className="card">
          {"Longitude: " + longitude}
        </div>

        <div className="card">
          {"Location age: " + timeFromSeconds(locationAge)}
        </div>

        <div className="card">
          {"Ping age: " + timeFromSeconds(pingAge)}
        </div>

        <div className="card">
          {"Tracking: " + trackingStatus(trackingRequested)}
        </div>
        <div className="card">
        <Button size="small" variant="contained" onClick={(e) => switchTracking()}>
          {statusText}
        </Button>
        </div>

      </div>
    );
  };

  const theme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#467cf3"
      },
      secondary: {
        main: "#ffa500"
      },
      text: {
        primary: "#467cf3"
      }
    }
  })

  //////////
  // HTML //
  //////////
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <div className="leftPane">
          <div className="middleColumn">
            <div className="search">
              <TextField
                className="deviceNameInput"
                error={isStatusError(status)}
                helperText={isStatusErrorText(status)}
                id="device-name-input"
                label="Device name"
                variant="outlined"
                placeholder="Enter device name"
                value={deviceName}
                onChange={(e) => setDeviceName(e.target.value)}
                focused
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Button size="large" variant="contained" onClick={(e) => searchButtonClicked()}>SEARCH</Button>

                {deviceData && <Button size="large" variant="contained" onClick={(e) => setShowDataOnMap(!showDataOnMap)}>
                  {showDataOnMap ? "HIDE" : "SHOW"}
                </Button>}
              </div>
            </div>
            <div className="response">
              <Dashboard />
            </div>
          </div>
        </div>
        <div className="rightPane">
          <SimpleMap
            latitude={latitudeDegree}
            longitude={longitudeDegree}
          />
        </div>
      </div>
    </ThemeProvider>
  )
}

const rootElement = document.getElementById("root")
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  rootElement
)
